import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import { Image } from "react-native";

interface Props {}

interface State {}

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Image
                    resizeMode="cover"
                    source={require("../../assets/images/logo.png")}
                    style={{ width: 250, height: 250, marginTop: 50, paddingBottom: 20 }}
                />

                <View style={{ alignItems: "center" }}>
                    <BoldText fontSize={22} align="center">
                        {t("welcome_to")} {"LatamXO"}
                    </BoldText>
                    <RegularText fontSize={16}>{t("a_portal")}</RegularText>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
