const nunitoBold = require("./Montserrat-Bold.ttf")
const nunitoLight = require("./Montserrat-Light.ttf")
const nunitoRegular = require("./Montserrat-Regular.ttf")
const nunitoSemiBold = require("./Montserrat-SemiBold.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
